import { connect } from '../../utils/redux';
import { AddPrestoCluster } from './components';
import * as selectors from './selectors';
import * as actions from './actions';
import PrestoUsers from '../PrestoUsers';
import IdentityProviders from '../IdentityProviders';
import PrestoUsersAdd from '../PrestoUsersAdd';
import ComputePlane from '../ComputePlane';

const mapStateToProps = (state) => {
  return {
    errors: selectors.getErrors(state),
    submitInProgress: selectors.getSubmitInProgress(state),
    submitSuccess: selectors.getSubmitSuccess(state),
    usePop: selectors.getUsePop(state),
    prestoUsers: PrestoUsers.selectors.getPrestoUsers(state),
    prestoUsersLoading: PrestoUsers.selectors.getLoadingInProgress(state),
    identityProvider: IdentityProviders.selectors.getIdentityProvider(state),
    identityProviderLoading:
      IdentityProviders.selectors.getLoadingInProgress(state),
    enableIdentityProviderFeature:
      IdentityProviders.selectors.getEnableExternalIdentityProvider(state),
    enableEnableSpot: selectors.getEnableSpot(state),
    maxWorkerNodeCount: selectors.getMaxWorkerNodeCount(state),
    prestoCPPEnabled: selectors.getPrestoCPPEnabled(state),
    instanceTypeValues: selectors.getInstanceTypeValues(state),
    coordinatorInstanceTypeValues:
      selectors.getCoordinatorInstanceTypeValues(state),
    hmsInstanceTypeValues: selectors.getHmsInstanceTypeValues(state),
    awsAZSelect: selectors.getAZSelect(state),
    computePlaneId: ComputePlane.selectors.getActiveComputePlaneId(state),
    awsPrivateSubnetSelect: ComputePlane.selectors.getAwsPrivateSubnets(state),
    prestoVersions: selectors.getPrestoVersions(state),
    prestoVersionsLoading: selectors.getPrestoVersionsLoading(state),
  };
};

export default connect(mapStateToProps, {
  create: actions.submit,
  loadPrestoUsers: PrestoUsers.actions.getAll,
  resetCreatePrestoUser: PrestoUsersAdd.actions.resetForm,
  loadIdentityProviders: IdentityProviders.actions.getAll,
  loadPrestoVersions: actions.getVersions,
})(AddPrestoCluster);
